import { ComponentProps, ReactNode, useRef, useState } from 'react';

import clsx from 'clsx';

import { IonInput, IonSpinner } from '@ionic/react';

import SearchIcon from '@/components/icons/common/SearchIcon.tsx';

export default function Input({
  autocompletes,
  errorText,
  isAutocompleteLoading,
  leftIcon,
  onAutocompleteClick,
  rightIcon,
  ...props
}: ComponentProps<typeof IonInput> & {
  autocompletes?: string[];
  errorText?: string;
  isAutocompleteLoading?: boolean;
  leftIcon?: ReactNode;
  onAutocompleteClick?: (value: string) => void;
  rightIcon?: ReactNode;
}) {
  const [isFocused, setIsFocused] = useState(false);
  const ref = useRef<HTMLIonInputElement>(null);

  // console.log(ref.current?.blu);
  return (
    <div className="relative flex flex-col items-center justify-center gap-1">
      <IonInput
        className={clsx(
          'h-13 rounded-12 border !px-4 text-16 lg:text-18',
          isFocused ? 'border-gobazar-400/50' : 'border-neutral-200',
        )}
        mode="ios"
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
        ref={ref}
        type="text"
        {...props}
      >
        {!!leftIcon && (
          <div className="!mr-3 md:!mr-4" slot="start">
            {leftIcon}
          </div>
        )}
        {!!rightIcon && (
          <div className="" slot="end">
            {rightIcon}
          </div>
        )}
      </IonInput>

      {(isAutocompleteLoading || (!!autocompletes?.length && !!onAutocompleteClick)) &&
        isFocused && (
          <div
            className={clsx(
              'absolute left-0 top-[calc(100%-10px)] z-30 flex w-full flex-col gap-2 rounded-b-12 border border-t-0 bg-white p-4',
              isFocused ? 'border-gobazar-400/50' : 'border-neutral-200',
            )}
          >
            {isAutocompleteLoading && <IonSpinner className="text-gobazar" name="dots" />}
            {autocompletes?.map((autocomplete, index) => (
              <div
                className="group flex cursor-pointer flex-row items-center gap-1 py-2 text-[#2B2B2B] hover:!text-gobazar"
                key={`autocomplete-${index}`}
                onClick={() => {
                  onAutocompleteClick?.(autocomplete);
                  ref.current?.getInputElement().then((input) => input?.blur());
                }}
                onMouseDown={(e) => {
                  e.preventDefault();
                }}
              >
                <SearchIcon className="shrink-0 text-[#CCCCCC] transition-colors group-hover:text-gobazar" />
                <p className="truncate text-16 transition-colors">{autocomplete}</p>
              </div>
            ))}
          </div>
        )}

      {!!errorText && (
        <div className="text-14 font-5 text-red-500">
          <p>{errorText}</p>
        </div>
      )}
    </div>
  );
}
